import axios from "axios";
export const apiUrl = "https://farmclo.com:4044";
//http://farmclo.com:4040 -다모아텍 apiUrl
//http://commoncoding.io:4040 -커몬코딩 apiUrl
//http://192.168.0.10:4040 - local apiUrl
let flag = false;

export const setToken = (token) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const client = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    withCredentials: true,
  },
});

export const noclient = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    withCredentials: true,
  },
});

export const update = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
    withCredentials: true,
  },
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      if (!flag) {
        if (window.location.pathname !== "/login") {
          // onlogin();
          flag = true;
          reUserPut("/api/account/update/refresh");
        }
      }
    }
    //  else if (error.response.status < 200 || error.response.status > 299) {
    //   return alert(error.response.data.message);
    // }
  }
);

update.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      if (!flag) {
        if (window.location.pathname !== "/login") {
          // onlogin();
          flag = true;
          reUserPut("/api/account/update/refresh");
        }
      }
    }
    //  else if (error.response.status < 200 || error.response.status > 299) {
    //   return alert(error.response.data.message);
    // }
  }
);

export const loginPost = async (url, body) => {
  try {
    const res = await client.post(url, body);
    if (res.status >= 200 || res.status <= 299) {
      return res.data;
    } else {
      let message;
      if (res.status) {
        switch (res.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = res.message;
            break;
        }
      }
      alert(message);
    }
  } catch (error) {
    alert("아이디와 비밀번호를 확인해주세요");
  }
};

export const requestPost = async (url, body) => {
  const token = sessionStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  const res = await client.post(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const adminRequestPost = async (url, body) => {
  const token = sessionStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  const res = await update.post(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const requestPut = async (url, body) => {
  const token = sessionStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
    };
  }
  const res = await client.put(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const adminUserPut = async (url, body) => {
  const token = sessionStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  const res = await update.put(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const reUserPut = async (url, body) => {
  const token = sessionStorage.getItem("authUser");
  const longtoken = localStorage.getItem("long");
  let headers = {};
  if (token) {
    headers = {
      "x-jwt-refresh": `${longtoken}`,
    };
  }
  const res = await update.put(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    sessionStorage.setItem("authUser", res.data.accessToken);
    localStorage.setItem("long", res.data.refreshToken);
    flag = false;
    window.location.reload();
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const requestGet = async (url) => {
  const token = sessionStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  const res = await client.get(url, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};
export const norequestGet = async (url) => {
  const token = sessionStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  const res = await noclient.get(url, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const requestDelete = async (url) => {
  const token = sessionStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  const res = await client.delete(url, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const imgPut = async (url, body) => {
  const token = sessionStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
  }
  const res = await client.put(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};
